/*** New Design System Variables ***/
/***********************************/
$white: #fff;
$black: #000;

$gray-0: #fff;
$gray-1: #f9f9f9;
$gray-2: #efedee;
$gray-3: #9b9a9a;
$gray-4: #666163;
$gray-5: #000;

$green-0: #03cc5e;
$green-1: #00ae4f;
$green-2: #00853c;
$green-3: #006d31;
$green-4: #037436;

$lightblue-1: #b5def4;
$lightblue-2: #aad6ef;
$lightblue-3: #84d0fc;
$blue-1: #3431dc;
$blue-2: #080593;
$blue-3: #040279;

$orange-1: #ff7e4d;
$orange-2: #ff510e;
$orange-3: #cb3800;

$red-1: #ff0713;
$red-2: #d90000;

$lightpink-1: #ffedf1;
$lightpink-2: #ffd8e0;
$lightpink-3: #ffaebf;
$pink-1: #ffa8cc;
$pink-2: #f38bb6;
$pink-3: #cd4980;

$yellow-1: #ffec56;
$yellow-2: #fbe324;
$yellow-3: #ebd100;
$yellow-4: #a77e13;

$beige-1: #f9ecda;
$beige-2: #e6d3b8;
$beige-3: #debe8f;
$beige-4: #b56937;

$cyan-1: #20dbf1;
$cyan-2: #63dfbd;

$purple-1: #ad98c2;
$purple-2: #5c3185;

$black-lens: #4e4e4e;
$brown-lens: #664609;
$green-lens: hsla(105, 26%, 27%, 0.8);
$silver-reflective-lens: radial-gradient(100% 100% at 50.01% 0%, rgba(171, 245, 255, 0) 56.77%, rgba(171, 245, 255, 0.3) 100%),
	radial-gradient(84.42% 186.8% at 95.11% 14.94%, rgba(255, 255, 255, 0.6) 28.12%, rgba(255, 255, 255, 0) 83.33%),
	radial-gradient(170.37% 170.37% at 50.01% -29.07%, rgba(69, 173, 167, 0) 50.52%, rgba(69, 173, 167, 0.16) 50.53%),
	radial-gradient(60.18% 60.07% at 50.01% 48.37%, rgba(197, 243, 241, 0.94) 0%, rgba(107, 145, 142, 0.94) 100%), $gray-0;
$pink-reflective-lens: radial-gradient(109.15% 94.46% at 50% 5.58%, rgba(255, 184, 0, 0) 60.42%, rgba(255, 184, 0, 0.3) 100%),
	radial-gradient(86.73% 198.74% at 96.96% 17.7%, rgba(255, 255, 255, 0.5) 28.12%, rgba(255, 255, 255, 0) 83.33%),
	radial-gradient(170.37% 170.37% at 50.01% -29.07%, rgba(233, 203, 206, 0) 51.04%, rgba(179, 104, 116, 0.08) 51.05%),
	radial-gradient(60.18% 60.07% at 50.01% 48.37%, rgba(253, 235, 188, 0.94) 0%, rgba(198, 103, 126, 0.94) 100%), $gray-0;
$blue-reflective-lens: radial-gradient(100% 100% at 50.01% 0%, rgba(128, 0, 255, 0) 54.69%, rgba(128, 0, 255, 0.4) 100%),
	radial-gradient(88.58% 203.7% at 100.02% 15.81%, rgba(255, 255, 255, 0.5) 28.13%, rgba(255, 255, 255, 0) 83.33%),
	radial-gradient(170.37% 170.37% at 50.01% -29.07%, rgba(0, 206, 206, 0) 51.04%, rgba(0, 206, 206, 0.2) 51.05%),
	radial-gradient(60.18% 60.07% at 50.01% 48.37%, rgba(160, 238, 255, 0.95) 0%, rgba(0, 99, 247, 0.95) 100%), $gray-0;
$green-reflective-lens: radial-gradient(100% 100% at 50% 0%, rgba(0, 10, 255, 0) 60.42%, rgba(0, 10, 255, 0.3) 100%),
	radial-gradient(86.5% 198.26% at 96.9% 18.96%, rgba(255, 255, 255, 0.5) 28.13%, rgba(255, 255, 255, 0) 83.33%),
	radial-gradient(170.37% 170.37% at 50.01% -29.07%, rgba(0, 188, 248, 0) 51.04%, rgba(0, 188, 248, 0.12) 51.05%),
	radial-gradient(60.18% 60.07% at 50.01% 48.37%, rgba(173, 255, 109, 0.94) 0%, rgba(0, 170, 27, 0.94) 100%), $gray-0;

/*** Accessory Colors ***/
/************************/
$blue-denim: #5175b0;
$blue-navy: #504b90;
$blue-classic: linear-gradient(90deg, $lightblue-2 50%, $green-2 50%);
$clear: #c9c7c3;
$perry: linear-gradient(90deg, $gray-2 50%, $green-2 50%);
$teal: #009fbf;
$violet: #b40070;

/*** Old Design System ***/
/*************************/
$green-tint: #00ae4f;
$green: #00ae4f;
$green-a11y: #00853c;
$green-shade: #037436;
$green-light: #e5f7Ed;

$lightblue-tint: #b5def4;
$lightblue: #aad6ef;
$lightblue-shade: #84d0fc;

$blue-tint: #3431dc;
$blue: #080593;
$blue-shade: #040279;

$orange-tint: #ff7e4d;
$orange: #ff510e;
$orange-shade: #cb3800;

$pink-tint: #ffa8cc;
$pink: #f38bb6;
$pink-shade: #cd4980;

$lightpink-tint: #ffedf1;
$lightpink: #ffd8e0;
$lightpink-shade: #ffaebf;

$yellow-tint: #ffec56;
$yellow: #fbe324;
$yellow-shade: #ebd100;

$beige-tint: #f9ecda;
$beige: #e6d3b8;
$beige-shade: #debe8f;

$red: #ff0713;
$red-shade: #d90000;

$cyan: #20dbf1;
$cyan-shade: #63dfbd;

$purple-tint: #ad98c2;
$purple: #5c3185;
