@import 'colors';

@function em-calc($size) {
	$em-size: calc($size / 16px * 1em);
	@return $em-size;
}

// Media query ignores 62.5% font size
@function rem-calc($size) {
	$rem-size: calc($size / 16px * 1rem);
	@return $rem-size;
}

/*** Animation ***/
/*****************/
$bezier-1: cubic-bezier(0.19, 0.5, 0.22, 1);
$bezier-2: cubic-bezier(0.19, 1, 0.22, 1);

$transition-1: 100ms;
$transition-2: 150ms;
$transition-3: 200ms;
$transition-4: 250ms;
$transition-5: 300ms;

/*** Border Radii ***/
/********************/
$radius-1: 0.2rem;
$radius-2: 0.4rem;
$radius-3: 0.6rem;
$radius-4: 0.8rem;
$radius-5: 1.2rem;
$radius-6: 1.6rem;
$radius-7: 3.2rem;
$radius-round: 50%;
$radius-pill: 360px;

/*** Sizing Variables ***/
/************************/
$gutter-site: 3.2rem;
$gutter-site-mobile: 1.6rem;

/*** Breakpoints ***/
/*******************/
$media-xxsm: em-calc(375px);
$media-xsm: em-calc(400px);
$media-sm-max: em-calc(575px);
$media-sm: em-calc(576px);
$media-md-max: em-calc(768px);
$media-md: em-calc(769px);
$media-md-lg: em-calc(960px);
$media-lg-max: em-calc(991px);
$media-lg: em-calc(992px);
$media-xl-max: em-calc(1023px);
$media-xl: em-calc(1024px);
$media-xxl: em-calc(1440px);
$media-xxxl: em-calc(1600px);
$media-xxxl-max: em-calc(1750px);

/*** Shadows ***/
/***************/
$shadow-pink: 0 0 0 2px $pink-2;
$shadow-blue: 0 0 0 2px $blue-2;
$shadow-green: 0 0 0 2px $green-2;
$drop-shadow-1: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.24));
$text-shadow-1:  1px 1px 0px rgba(0, 0, 0, 0.56), 0px 4px 4px rgba(0, 0, 0, 0.32);

/*** Z Indices ***/
/*****************/
$zindex-1: 10;
$zindex-2: 20;
$zindex-3: 30;
$zindex-4: 40;
$zindex-5: 50;
$zindex-6: 60;
$zindex-7: 70;
$zindex-8: 80;
$zindex-9: 90;
$zindex-10: 100;
$zindex-11: 110;
$zindex-min: 1;
$zindex-max: 1000;
$zindex-max-1: 1001;
$zindex-max-2: 1002;
$zindex-max-20: 2000;
$zindex-behind: -1;

/*** Swiper Carousel ***/
/***********************/
$swiper-pagination-bullet-inactive-color: #000;
$swiper-pagination-bullet-inactive-opacity: 0.2;
$swiper-pagination-bullet-opacity: 1;
$swiper-navigation-size: 44px;

/*** Defined Heights & Widths ***/
/********************************/
$headerHeight: 9.2rem;
$footerHeight: 35rem;
$mainHeight: calc(100vh - $headerHeight);
$header-height--mobile: 6rem;
$pdp-left-rail: 66.7%;
$pdp-right-rail: 33.3%;
$scrollNavHeight: 4.8rem;
$announcement-bar-height: 3.2rem;
$max-width: 1920px;
$site-header-height: 6rem + $announcement-bar-height;
$tabbed-navigation-height: 4.4rem;

/*** Media Query Value ***/
/*************************/
$mq-xsmall: 37.5em;
$mq-small: 57.6em;
$mq-medium: 76.8em;
$mq-large: 99.2em;
$mq-xlarge: 102.4em;
$mq-xxlarge: 144em;
$mq-xxxlarge: 160em;

/*** Opacity ***/
/***************/
$link-opacity: 0.6;

/*** Swatch Color ***/
/*************************/
$swatchColors: (
    "Black": $black-lens,
    "Brown": $brown-lens,
    "Green": $green-lens,
    "Silver Reflective": $silver-reflective-lens,
    "Pink Reflective": $pink-reflective-lens,
    "Blue Reflective": $blue-reflective-lens,
    "Green Reflective": $green-reflective-lens,
);

$swatchImages: (
    "Blue Clear": 'https://cdn.shopify.com/s/files/1/1147/9910/files/Blue-Clear-Swatch.png?v=1682957005',
    "Blue Tortoise": 'https://cdn.shopify.com/s/files/1/1147/9910/files/Blue-Tortoise-Swatch.png?v=1682956995',
    "Crystal Clear": 'https://cdn.shopify.com/s/files/1/1147/9910/files/Crystal-Clear-Swatch.png?v=1682956984',
    "Clear": 'https://cdn.shopify.com/s/files/1/1147/9910/files/Crystal-Clear-Swatch.png?v=1682956984',
    "Pink Clear": 'https://cdn.shopify.com/s/files/1/1147/9910/files/Pink-Clear-Swatch.png?v=1682956972',
    "Tortoise": 'https://cdn.shopify.com/s/files/1/1147/9910/files/Tortoise-Swatch.png?v=1682956826',
    "Honey Clear": 'https://cdn.shopify.com/s/files/1/1147/9910/files/Honey-Clear-Swatch_v2.png?v=1736794135',
    "Gray Clear": 'https://cdn.shopify.com/s/files/1/1147/9910/files/Gray-Clear-Swatch_v2.png?v=1736794135',
    "Lavender Clear": 'https://cdn.shopify.com/s/files/1/1147/9910/files/Lavender-Clear-Swatch_v2.png?v=1736794135',
);

$optionColors: (
    "Black": $black,
    "Classic Blue": $blue-classic,
    "Clear": $clear,
    "Denim Blue": $blue-denim,
    "Green": $green,
    "Navy": $blue-navy,
    "Orange": #f9a301,
    "Perry": $perry,
    "Pink": #f9bac8,
    "Red": #d00000,
    "Tan": $beige-4,
    "Teal": $teal,
    "Violet": $violet,
    "Yellow": #efd600,
);

/*** Screen-specific Variables ***/
/*********************************/
$iphone-13-width: 66.6rem;
$iphone-13-height: 70rem;
$cart-action-break: 1084px;

/*** Contentful Variables ***/
/****************************/
$width-auto: auto;
$width-375: 3.75rem;
$width-576: 5.76rem;
$width-767: 7.67rem;
$width-1024: 102.4rem;
$width-1280: 128rem;
$width-1440: 144rem;

$height-128: 12.8rem;
$height-256: 25.6rem;
$height-375: 37.5rem;
$height-444: 44.4rem;
$height-512: 51.2rem;
$height-767: 76.7rem;
$height-932: 93.2rem;
