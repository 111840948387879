@import '__importable.scss';
.container {
	position: relative;
	width: 100%;
	min-width: 100%;
	height: 100%;

	&:not(.buildflow):not(.bf-all-tops) {
		display: flex;

		> :first-child {
			flex-grow: 0;
			flex-shrink: 1;
			flex-basis: 33.2rem;
		}
		> :not(:first-child) {
			flex: 3;
		}
	}

	&:not(.buildflow):not(.bf-all-tops) {
		@media screen and (min-width: $media-lg) {
			padding: 0 1.6rem 4.4rem;

			> :first-child {
				flex-basis: 26rem;
			}
		}
		@media screen and (min-width: $media-xl) {
			padding: 0 3.2rem 4.4rem;
		}
		@media screen and (min-width: $media-xxl) {
			padding: 0 6.4rem 4.4rem;
		}
	}
}

.allTops {
	padding-bottom: 0 !important;

	@media screen and (width > $media-lg) {
		padding-bottom: 2rem !important;
	}
}
