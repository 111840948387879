@import '__importable.scss';
$mobile-min-height: rem-calc(812px);

.modal {
	padding: 1.6rem;
	max-height: 85vh;
	height: 100%;
	overflow: hidden;
	overflow-y: scroll;

	@media screen and (min-width: $media-sm) {
		padding: 3.2rem;
		translate: -50% 0 !important;
		bottom: 0 !important;
		top: auto !important;
		height: auto;
		overflow-y: auto;
	}
}

.sectionContainer {
	width: 100%;
	padding: 2.4rem 0 1.6rem;
	border-bottom: 1px solid $gray-2;
}

.button {
	position: relative;
	bottom: 0;
	height: min-content;
	cursor: pointer;
	z-index: $zindex-10;

	//@media screen and (min-width: $media-md) {
	//	margin-top: auto;
	//}

	@media screen and (min-width: $media-md-lg) {
		margin-bottom: 1.6rem;
	}
}

.buttonText {
	color: $blue-2;
	font-size: 1.8rem;
	font-weight: 500 !important;
}
