@import '__importable.scss';
.container {
	$headingContentPadding: 1.6rem 0 0.8rem 0;
	width: 100%;

	z-index: $zindex-4;
	gap: 2rem;

	&--search {
		border-bottom: 1px solid $gray-2;
		padding: 0.8rem 1.6rem;
	}

	&.buildflow {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		width: 100%;
		position: sticky;
		top: 0;
		overflow-y: unset;
		background-color: $gray-0;
		z-index: $zindex-4;

		.heading,
		.filters,
		.refinements {
			z-index: inherit;
			background-color: inherit;
		}

		.heading {
			grid-area: 1 / 1 / 2 / 2;
			position: relative;
			top: 1px;
			width: max-content;
			border-top: 1px solid $gray-2;
			border-right: 1px solid $gray-2;
			border-top-right-radius: 0.4rem;
			height: 2.4rem;
			align-items: center;
			padding: 0.2rem 0.8rem;
			justify-content: center;
			z-index: $zindex-6;

			> :first-child {
				margin-right: 0.4rem;
			}
		}

		.top-frame-education {
			position: relative;
			grid-area: 1 / 1 / 2 / 4;

			@media screen and (width < $media-xsm) {
				left: 1rem;
			}
		}

		.filters {
			grid-area: 2 / 1 / 3 / 6;
			display: grid;
			grid-template-columns: repeat(3, minmax(0, 1fr));
			gap: 0.4rem;
			border-top: 1px solid $gray-2;
			padding: 0.8rem;
			z-index: $zindex-5;

			>* {
				flex: 1 0 0;
			}

			@media screen and (width < $media-lg) {
				border-bottom: 1px solid $gray-2;
			}
		}

		.refinements {
			grid-area: 3 / 1 / 3 / 6;
			display: flex;
			align-self: stretch;
			justify-content: flex-start;
			gap: 0.4rem;

			/* Optimized transform, do NOT animate height */
			height: 1px;
			transform: scale(1, 0);
			transform-origin: top;
			will-change: transform;

			&.show-refinements {
				padding: 0.8rem 0.8rem 0.8rem 0; // Disables left padding for scroll UX in CurrentRefinements.module.scss
				height: auto;
				transform: scale(1, 1);
				transform-origin: top;
				transition: transform 150ms linear;

				.clear-btn {
					display: flex;
				}
			}
		}
	}

	@media screen and (width > $media-lg) {
		&.buildflow {
			display: flex;
			flex-flow: row wrap;
			height: auto;

			> :not(:nth-of-type(1)) {
				flex: 1;
			}

			.heading {
				flex-flow: column;
				height: auto;
				top: unset;
				border: none;
				border-radius: 0;
				padding: $headingContentPadding;
				padding-right: 0.8rem;
				align-items: flex-start;
			}

			.filters {
				height: auto;
				border: none;
				gap: 0.8rem;
				padding: $headingContentPadding;

				>* {
					flex: 1 0 0;
				}
			}

			.refinements {
				flex-basis: 100%;

				&.show-refinements {
					padding: 0;
					padding-bottom: 0.8rem;
					margin-block: 0.8rem;
				}
			}
		}
	}

	&:not(.buildflow) {
		position: sticky;
		top: $headerHeight;

		.heading {
			> :first-child {
				margin-right: 0.8rem;
			}
		}
	}

	.search-heading{
		display: flex;
		flex-direction: column;
		gap: 1rem;

		@media screen and (min-width: $media-md) {
			flex-direction: row;
		}

		@media screen and (width > $media-lg) {
			padding: 1.6rem 2.4rem;
		}

		@media screen and (width > $media-xl) {
			padding: 1.6rem 3.2rem;
		}

		@media screen and (width > $media-xxl) {
			padding: 1.6rem 6.4rem;
		}
	}

	&.hidden{
		display: none;
	}
}

.wrapper{
	background-color: $gray-0;
	z-index: $zindex-4;
	position: sticky;
	top: $headerHeight;
}

.all-top-wrapper{
	border-top: 1px solid $gray-2;
	border-bottom: 1px solid $gray-2;
	top: 0;
	padding: 0.8rem 1.6rem;
	margin-bottom: 1.6rem;

	@media screen and (min-width: $media-sm-max) {
		padding: 1.6rem;
	}
}

.filtersButton {
	padding-inline: 0.8rem;
	padding-bottom: 1.6rem;
	width: 100%;
	min-width: 25%;

	@media screen and (min-width: $media-md-max) {
		padding-inline: 1.6rem;
		width: auto;
	}

	@media screen and (min-width: $media-xl) {
		padding-inline: 3.2rem;
		padding-bottom: 0;
	}

	@media screen and (min-width: $media-xxl) {
		padding-inline: 6.4rem;
	}


	&BfTops {
		padding-inline: 0;
		min-width: 17rem;
		padding-bottom: 0;

		@media screen and (min-width: $media-md-max) {
			padding-inline: 0;
			width: auto;
		}
	}
}
