@import '__importable.scss';
.container {
	width: 100%;
	height: 100%;
	position: relative;

	@media screen and (width > $media-md) {
		margin: 0;
	}

	&.buildflow {
		padding-block: 0;

		.tops {
			padding-inline: 0;
		}

		.tops--double {
			margin-block: 0;
		}

		&.show-refinements {
			.virtuoso > div {
				padding-top: 0;
			}
		}

		.virtuoso > div {
			padding-top: 0.8rem;
		}
	}

	&:not(.buildflow) {
		.virtuoso {
			@media screen and (min-width: $media-lg) {
				padding-block: 1.6rem;
			}
			>div {
				padding-block: inherit;
			}
		}
	}
}

.virtuoso {
	height: 100%;
	@include noScrollBar;
}

.tops {
	display: grid;
	gap: 0.8rem;
	grid-template-columns: 1fr;
	width: 100%;
	padding: 0 0.8rem;

	@media screen and (min-width: $media-sm) {
		grid-template-columns: repeat(2, 1fr);
		
		[class*='BuildFlowCard_container'] {
			min-height: 190px;
		}
	}

	@media screen and (min-width: $media-lg) {
		grid-template-columns: repeat(3, 1fr);

		[class*='BuildFlowCard_container'] {
			min-height: 210px;
		}
	}

	@media screen and (min-width: $media-lg-max) {
		padding-right: 0;
	}

	@media screen and (min-width: $media-xxl) {
		grid-template-columns: repeat(4, 1fr);

		&--is-open {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	&--double {
		grid-template-columns: repeat(2, 1fr);
		margin-block: 0.8rem;
		margin-inline: 0;

		[class*='BuildFlowCard_container'] {
			min-height: 190px;
		}

		@media screen and (min-width: $media-lg) {
			grid-template-columns: repeat(3, 1fr);

			[class*='BuildFlowCard_container'] {
				min-height: 210px;
			}
		}

		@media screen and (min-width: $media-xxl) {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}

.loading-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: $zindex-2;
}

.loading {
	position: fixed;
	top: 50%;
	left: 50%;
}

.no-results {
	background-color: $gray-1;
	border-radius: 0.8rem;
	padding: 1.6rem;
	margin: 1.6rem;

	@media screen and (min-width: $media-md) {
		padding: 2.4rem;
		margin: 3.2rem;
	}
}
