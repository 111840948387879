@import '__importable.scss';
.accordion {
	width: 100%;
	padding-inline: 0.8rem;

	@media screen and (min-width: $media-md-max) {
		padding-inline: 1.6rem;
	}

	@media screen and (min-width: $media-xl) {
		padding-inline: 3.2rem;
	}

	@media screen and (min-width: $media-xxl) {
		padding-inline: 6.4rem;
	}

	@media screen and (min-width: $max-width) {
		padding-inline: 12.8rem;
	}

	.content {
		margin-top: 0.8rem;
	}

	&--isAllTops {
		@media screen and (min-width: $max-width) {
			padding-inline: 6.4rem;
		}
	}
}

.additionalInfo {
	padding-block: 2.4rem;

	&--header,
	&--swiper {
		padding: 0 1.6rem !important;

		@media screen and (min-width: $media-md-max) {
			padding: 0 2.4rem !important;
		}

		@media screen and (min-width: $media-xl) {
			padding: 0 3.2rem !important;
		}

		@media screen and (min-width: $media-xxl) {
			padding: 0 6.4rem !important;
		}
	}

	&--swiper {
		padding-block: 0.8rem !important;

		@media screen and (min-width: $media-xxl) {
			padding-block: 1.6rem !important;
		}
	}
}

.slide {
	height: auto !important;

	.slideContent {
		min-height: 100%;

		.title {
			font-size: 1.6rem !important;
		}
	}
}

.navigation-container {
	padding: 0 1.6rem 1.6rem 0;
	@media screen and (min-width: $media-md) {
		padding-bottom: 2.4rem;
	}
}

.button-prev,
.button-next {
	@include navigationButton;
	position: initial;
	width: 3.2rem;
	height: 3.2rem;
	z-index: $zindex-1;

	@media (max-width: $media-md) {
		display: flex;
	}
}

.disabled {
	background-color: $gray-2;
	color: $gray-3;
	cursor: unset;
}

.hidden {
	display: none;
}
